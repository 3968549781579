export const apiEndpoint = {
    data() {
      return {
        // VUE_APP_API_URL: "http://localhost:8000",
        // VUE_FINMAN_AUTH_API: "http://localhost/finman-cloud/api/inventory/verifyToken"
        VUE_APP_API_URL: "https://dtl-inventory-be.skydigitalbd.com",
        VUE_FINMAN_AUTH_API: "https://dtl.finman-cloud.com/api/inventory/verifyToken"

}
    },
    methods: {
      numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    }
  }